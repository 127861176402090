
.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
}

.button-text {
  color: black;
  text-decoration: none;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.button {
  margin-left: 50px;
  background-color: white;
  border-color: white;
  box-shadow: none;
  border-radius: 10px;
  border: transparent;
}

.button:hover {
  background-color: rgba(162, 238, 191, 0.918);
  border-color: black;
}