.chess-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between links */
    padding: 20px;
}

.chess-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333; /* Neutral color */
    background-color: #f5f5f5; /* Light background */
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.chess-link:hover {
    background-color: #e0e0e0; /* Slight hover effect */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.chess-icon {
    width: 30px; /* Adjust icon size */
    height: 30px;
    margin-right: 10px;
}