.container {
    display: flex;
    align-items: center;
}

.image {
    display: block;
    max-width: 600px;
    max-height: 600px;
    width: 32%;
    height: 32%;
    padding: 10px;
    padding-left: 20px;

}