.tab-button {
    flex: 1; /* Each button takes an equal portion of the container width */
    padding: 15px 0;
    text-align: center;
    border: none;
    background-color:  transparent;
    color: black;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-button.active {
    background-color: white; /* Active tab color */
    color: darkblue;
    border-bottom: 2px solid darkblue; /* Remove bottom border when active */
}

.tab-button:hover {
    background-color: #e1e1e1; /* Hover effect */
}