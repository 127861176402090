.title {
    color: white;
    background-color: rgba(50, 218, 168, 0.692);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    font-size: 2.5em;
    padding-top: 0px;
}

.tab-container {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    align-items: center;
    gap: 10px; /* Spacing between tabs */
    border-bottom: 2px solid #ccc; /* Optional border for tab area */
}