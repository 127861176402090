.title {
    color: white;
    background-color: rgba(50, 218, 168, 0.692);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    font-size: 2.5em;
}

.intro-text {
    color: white;
    padding-top: 10px;
    background-color: rgba(50, 218, 168, 0.692);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    font-size: 1.1em;
    padding-bottom: 10px;
}

.summary {
    padding-bottom: 5px;
    font-size: 1.3em;
}

.text-box {
    margin-left: 20%;
    margin-right: 20%;
}

.normal-text {
    font-size: 1em;
}

.par-text-box {
    margin-left: 15%;
    margin-right: 15%;
}

.am-container {
    display: flex;
    align-items: center;
}

.am-image {
    max-width: 290px;
    max-height: 290px;
    width: 15%;
    height: 15%;
    padding: 10px;
    padding-left: 20px;
}